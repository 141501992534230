const STATUS = {
  NEW: "NEW",
  OPEN: "OPEN",
  CLOSED: "CLOSED",
  PENDING: "PENDING",
  DRAFT: "DRAFT",
  TOTREAT: "TOTREAT",
};

const STATUS_COLORS_BG = {
  NEW: "#FFDBD9",
  OPEN: "#FEF3C7",
  CLOSED: "#D1F9E5",
  PENDING: "#9AD2FF",
  DRAFT: "#FFA500",
};

const SOURCES = {
  CHAT: "CHAT",
  MAIL: "MAIL",
  PLATFORM: "PLATFORM",
  FORM: "FORM",
};

const SORTING = {
  AGENT: "AGENT",
  CREATIONDATE: "CREATIONDATE",
  UPDATEDATE: "UPDATEDATE",
};

const FEEDBACK = {
  NEUTRAL: "NEUTRAL",
  WOW: "WOW",
  THANKS: "THANKS",
  UNHAPPY: "UNHAPPY",
};

const PARCOURSLIST = ["VOLONTAIRE", "CLE"];

export { STATUS, STATUS_COLORS_BG, SOURCES, SORTING, FEEDBACK, PARCOURSLIST };
